

div[name="react-console-emulator__content"] { 
  box-sizing:border-box
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --amplify-primary-color: #2196f3;
  --amplify-primary-tint: #2196f3;
  --amplify-primary-shade: #000;
}

amplify-container {
  /* background-image: radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%); */
  background-image: linear-gradient(to right bottom, #125578, #1b6889, #257b9a, #318eaa, #3fa2ba, #3ca8bb, #3bafbb, #3eb5ba, #31aeab, #28a79a, #24a089, #279878);
}
