@import "@aws-amplify/ui-react/styles.css";

[data-amplify-authenticator][data-variation=modal]::before{
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(120deg, #1666a6 0%, #0f4673 47%, #082640 100%);
    opacity: 1;
}

body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif
}

[data-amplify-authenticator] {

    /*From material ui v.4.12.3 theme*/
    --palette-primary-light:#7986cb;
    --palette-primary-main:#3f51b5;
    --palette-primary-dark:#303f9f;
    --palette-primary-contrastText:#fff;

    --palette-secondary-light:#6ec6ff;
    --palette-secondary-main:#2196f3;
    --palette-secondary-dark:#0069c0;
    --palette-secondary-contrastText:#fff;

    --palette-error-light:#e57373;
    --palette-error-main:#f44336;
    --palette-error-dark:#d32f2f;
    --palette-error-contrastText:#fff;

    --palette-text-primary:rgba(0,0,0,0.87);
    --palette-text-secondary:#757575; /*rgba(0,0,0,0.6);*/
    --palette-text-disabled:rgba(0,0,0,0.12);

    --palette-grey-50:#fafafa;
    --palette-grey-100:#f5f5f5;
    --palette-grey-200:#eeeeee;
    --palette-grey-300:#e0e0e0;
    --palette-grey-400: #bdbdbd;
    --palette-grey-500: #9e9e9e;
    --palette-grey-600: #757575;
    --palette-grey-700: #616161;
    --palette-grey-800: #424242;
    --palette-grey-900: #212121;
    --palette-grey-A100: #d5d5d5;
    --palette-grey-A200: #aaaaaa;
    --palette-grey-A400: #303030;
    --palette-grey-A700: #616161;

    --mui-text-button-hover-background-color:rgba(63, 81, 181, 0.04);
    --mui-text-button-active-background-color:#3f51b5;
    
    --amplify-colors-background-primary: var(--palette-grey-50);
    --amplify-colors-background-secondary: var(--palette-grey-100);

    --amplify-components-tabs-item-color:var(--palette-text-secondary);
    --amplify-components-tabs-item-hover-color:var(--palette-text-primary);
    --amplify-components-tabs-item-active-color: var(--palette-secondary-main);
    --amplify-components-tabs-item-active-border-color: var(--palette-secondary-main);
    --amplify-components-tabs-item-focus-color: var(--palette-secondary-main);

    --amplify-components-button-primary-background-color: var(--palette-secondary-main);
    --amplify-components-button-primary-hover-background-color: var(--palette-secondary-main);
    --amplify-components-button-primary-active-background-color: var(--palette-secondary-dark);
    --amplify-components-button-primary-focus-background-color: var(--palette-secondary-main);
    --amplify-components-text-color: var(--amplify-colors-font-interactive);

    --amplify-components-button-active-color: none;
    --amplify-components-button-focus-color: none;
    --amplify-components-button-hover-color: none;
    --amplify-components-button-focus-border-color: none;
    --amplify-components-button-focus-box-shadow: none;
    --amplify-components-button-focus-box-shadow: none;
    --amplify-components-button-active-background-color: #e8f0fe;
    --amplify-components-button-hover-background-color: none;
    --amplify-components-button-hover-border-color: #aeb3b7;


    --amplify-components-button-primary-focus-box-shadow: none;

    --amplify-components-fieldcontrol-focus-border:none;
    --amplify-components-fieldcontrol-focus-box-shadow:none;

    --amplify-components-button-link-color: var(--palette-primary-main);
    --amplify-components-button-link-hover-color: var(--palette-primary-main);
    --amplify-components-button-link-hover-background-color: var(--mui-text-button-hover-background-color);
    --amplify-components-button-link-focus-color: var(--palette-primary-main);
    --amplify-components-button-link-focus-background-color: var(--mui-text-button-hover-background-color);
    --amplify-components-button-link-active-color: var(--palette-primary-main);
    --amplify-components-button-link-active-background-color: var(--mui-text-button-active-background-color:);
    --amplify-components-button-link-focus-box-shadow: none;
    --amplify-components-button-link-active-background-color: var(--mui-text-button-hover-background-color);

    --amplify-components-heading-color: var(--palette-text-primary);

    --amplify-components-text-color: var(--palette-text-primary);

    --amplify-components-checkbox-button-border: none;
    --amplify-components-checkbox-button-error-border-color:var(--palette-error-dark);
    --amplify-components-checkbox-icon-background-color:var(--palette-secondary-main);

    --amplify-components-fieldmessages-error-color:var(--palette-error-main);


    --amplify-components-link-color:var(--palette-primary-dark);
    --amplify-components-link-hover-color:var(--palette-primary-main);  
    
    --amplify-components-alert-error-color: var(--palette-error-dark);

    --amplify-components-fieldcontrol-error-focus-box-shadow:none;
    --amplify-components-fieldcontrol-error-border-color: var(--palette-error-main);


    --amplify-components-card-elevated-background-color:var(--palette-grey-50);
}

.amplify-text[data-variation=tertiary] {
    font-size: var(--amplify-components-fieldmessages-error-font-size) ;
}

[data-amplify-authenticator][data-variation=modal] [data-amplify-router-content] {
    background: none;/* #3f51b5; /*var(--palette-grey-50);*/
}

.amplify-button[data-variation=primary]:focus{
    border-width: 0px;
}

.terms-condition-flex {
    min-height: 100vh;
    justify-content: center;
    background-image: linear-gradient(120deg, #1666a6 0%, #0f4673 47%, #082640 100%);
    gap: 3rem;
    padding: 36px;
    align-items: center;
}

.terms-condition-card {
    max-width: 1080px;
    min-width: 600px;
    padding: 24px;
}

.terms-condition-top-button {
    --palette-primary-light:#7986cb;
    --palette-primary-main:#3f51b5;
    --palette-primary-dark:#303f9f;
    --palette-primary-contrastText:#fff;
    --mui-text-button-hover-background-color:rgba(63, 81, 181, 0.04);
    --mui-text-button-active-background-color:#3f51b5;

    --amplify-components-button-link-color: var(--palette-primary-main);
    --amplify-components-button-link-hover-color: var(--palette-primary-main);
    --amplify-components-button-link-hover-background-color: var(--mui-text-button-hover-background-color);

    --amplify-components-button-link-focus-color: var(--palette-primary-main);
    --amplify-components-button-link-focus-background-color: var(--mui-text-button-hover-background-color);
    --amplify-components-button-link-active-color: var(--palette-primary-main);
    --amplify-components-button-link-active-background-color: var(--mui-text-button-active-background-color:);
    --amplify-components-button-link-focus-box-shadow: none;
    --amplify-components-button-link-active-background-color: var(--mui-text-button-hover-background-color);
    margin-bottom: 1rem; 
}

.terms-condition-bottom-button {
    --palette-primary-light:#7986cb;
    --palette-primary-main:#3f51b5;
    --palette-primary-dark:#303f9f;
    --palette-primary-contrastText:#fff;
    --mui-text-button-hover-background-color:rgba(63, 81, 181, 0.04);
    --mui-text-button-active-background-color:#3f51b5;

    --amplify-components-button-link-color: var(--palette-primary-main);
    --amplify-components-button-link-hover-color: var(--palette-primary-main);
    --amplify-components-button-link-hover-background-color: var(--mui-text-button-hover-background-color);
    --amplify-components-button-link-focus-color: var(--palette-primary-main);
    --amplify-components-button-link-focus-background-color: var(--mui-text-button-hover-background-color);
    --amplify-components-button-link-active-color: var(--palette-primary-main);
    --amplify-components-button-link-active-background-color: var(--mui-text-button-active-background-color:);
    --amplify-components-button-link-focus-box-shadow: none;
    --amplify-components-button-link-active-background-color: var(--mui-text-button-hover-background-color);
    margin-top: 1rem;
    
}